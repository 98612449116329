// IMPORTANT: Also see runtimeConstants.js!

export const COUNTRY_FI = 'FI'
export const COUNTRY_SE = 'SE'
export const COUNTRY_GB = 'GB'
export const COUNTRY_DE = 'DE'
export const COUNTRY_PL = 'PL'

export const LANGUAGE_FI = 'fi'
export const LANGUAGE_SV = 'sv'
export const LANGUAGE_EN = 'en'
export const LANGUAGE_DE = 'de'
export const LANGUAGE_PL = 'pl'

export const COUNTRY_CODE_FINLAND = 'fi-fi'
export const COUNTRY_CODE_SWEDEN = 'sv-se'
export const COUNTRY_CODE_UNITED_KINGDOM = 'en-gb'
export const COUNTRY_CODE_GERMANY = 'de-de'
export const COUNTRY_CODE_POLAND = 'pl-pl'
export const COUNTRY_CODE_EN = 'en'

export const COUNTRY_GLOBAL = 'en'
export const DEFAULT_COUNTRY = COUNTRY_CODE_UNITED_KINGDOM

export const BUILD_COUNTRIES = [
  {
    templateRegex: '/fi-fi/asiakirjamallit/((?!kategoriat/))',
    templatePrefix: '/fi-fi/asiakirjamallit',
    categoryRegex: '/fi-fi/asiakirjamallit/kategoriat/',
    categoryPrefix: '/fi-fi/asiakirjamallit/kategoriat',
    articlePrefix: '/fi-fi/lakitieto',
    articleTopic: 'aihe',
    language: LANGUAGE_FI,
    countryCode: COUNTRY_FI
  },
  {
    templateRegex: '/sv-se/dokumentmallar/((?!kategorier/))',
    templatePrefix: '/sv-se/dokumentmallar',
    categoryRegex: '/sv-se/dokumentmallar/kategorier/',
    categoryPrefix: '/sv-se/dokumentmallar/kategorier',
    articlePrefix: '/sv-se/juridisk-kunskap',
    articleTopic: 'tema',
    language: LANGUAGE_SV,
    countryCode: COUNTRY_SE
  },
  {
    templateRegex: '/en-gb/legal-templates/((?!categories/))',
    templatePrefix: '/en-gb/legal-templates',
    categoryRegex: '/en-gb/legal-templates/categories/',
    categoryPrefix: '/en-gb/legal-templates/categories',
    articlePrefix: '/en-gb/legal-hub',
    articleTopic: 'topic',
    language: LANGUAGE_EN,
    countryCode: COUNTRY_GB
  },
  {
    templateRegex: '/de-de/rechtsdokumente/((?!kategorien/))',
    templatePrefix: '/de-de/rechtsdokumente',
    categoryRegex: '/de-de/rechtsdokumente/kategorien/',
    categoryPrefix: '/de-de/rechtsdokumente/kategorien',
    articlePrefix: '/de-de/rechtswissen',
    articleTopic: 'kategorie',
    language: LANGUAGE_DE,
    countryCode: COUNTRY_DE
  },
  {
    templateRegex: '/pl-pl/szablony/((?!kategorie/))',
    templatePrefix: '/pl-pl/szablony',
    categoryRegex: '/pl-pl/szablony/kategorie/',
    categoryPrefix: '/pl-pl/szablony/kategorie',
    articlePrefix: '/pl-pl/baza-prawna',
    articleTopic: 'kategorie',
    language: LANGUAGE_PL,
    countryCode: COUNTRY_PL
  }
]

export const BUILD_COUNTRIES_DICT = {}
for (const country of BUILD_COUNTRIES) {
  BUILD_COUNTRIES_DICT[country.countryCode] = country
}

export const COUNTRIES = {
  [COUNTRY_CODE_FINLAND]: {
    code: COUNTRY_CODE_FINLAND,
    countryCode: COUNTRY_FI,
    title: 'Suomi',
    name: '🇫🇮 Suomi',
    flag: '🇫🇮',
    language: LANGUAGE_FI
  },
  [COUNTRY_CODE_SWEDEN]: {
    code: COUNTRY_CODE_SWEDEN,
    countryCode: COUNTRY_SE,
    title: 'Sverige',
    name: '🇸🇪 Sverige',
    flag: '🇸🇪',
    language: LANGUAGE_SV
  },
  [COUNTRY_CODE_UNITED_KINGDOM]: {
    code: COUNTRY_CODE_UNITED_KINGDOM,
    countryCode: COUNTRY_GB,
    title: 'United Kingdom',
    name: '🇬🇧 United Kingdom',
    flag: '🇬🇧',
    language: LANGUAGE_EN
  },
  // NOTE: disabled until germany launch
  [COUNTRY_CODE_GERMANY]: {
    code: COUNTRY_CODE_GERMANY,
    countryCode: COUNTRY_DE,
    title: 'Deutschland',
    name: '🇩🇪 Deutschland',
    flag: '🇩🇪',
    language: LANGUAGE_DE
  },
  [COUNTRY_CODE_POLAND]: {
    code: COUNTRY_CODE_POLAND,
    countryCode: COUNTRY_PL,
    title: 'Polska',
    name: '🇵🇱 Polska',
    flag: '🇵🇱',
    language: LANGUAGE_PL
  },
  [COUNTRY_CODE_EN]: {
    code: COUNTRY_CODE_EN,
    countryCode: null,
    name: '🌎 Global',
    flag: '🌎',
    language: LANGUAGE_EN
  }
}

export const LANGUAGE_FLAGS = {
  [LANGUAGE_FI]: '🇫🇮',
  [LANGUAGE_SV]: '🇸🇪',
  [LANGUAGE_EN]: '🇬🇧',
  [LANGUAGE_DE]: '🇩🇪',
  [LANGUAGE_PL]: '🇵🇱'
}

export const COUNTRY_FLAGS = {
  [COUNTRY_FI]: '🇫🇮',
  [COUNTRY_SE]: '🇸🇪',
  [COUNTRY_GB]: '🇬🇧',
  [COUNTRY_DE]: '🇩🇪',
  [COUNTRY_PL]: '🇵🇱'
}

export const LAYOUT_HEADER_STYLE_WHITE = 'white'
export const LAYOUT_HEADER_STYLE_BLACK = 'black'
export const LAYOUT_HEADER_STYLE_DEEP_BLACK = 'deep-black'
export const LAYOUT_HEADER_STYLE_BEIGE = 'beige'
export const LAYOUT_HEADER_STYLE_BLUE = 'blue'
export const LAYOUT_HEADER_STYLE_LIGHTCREAM = 'light-cream'
export const LAYOUT_HEADER_STYLES = [LAYOUT_HEADER_STYLE_WHITE, LAYOUT_HEADER_STYLE_BEIGE, LAYOUT_HEADER_STYLE_BLACK, LAYOUT_HEADER_STYLE_DEEP_BLACK, LAYOUT_HEADER_STYLE_LIGHTCREAM, LAYOUT_HEADER_STYLE_BLUE] // first is default

export const legalHubArticleTypeIconsByLabel = {
  article: 'SolidDocumentTextIcon',
  guide: 'SolidBookOpenIcon',
  video: 'SolidPlayIcon',
  checklist: 'SolidClipboardListIcon',
  faq: 'SolidQuestionMarkCircleIcon',
}

export const legalHubArticleListExcludedFields = 'body,main_text,after_text,hero_image'

export const legalHubArticleExcludedTypes = 'PageContent'

// These are the content types we use for representing regular pages on storyblok
export const sbPageTypes = 'page,blogpost,LegalHubArticlePage'
